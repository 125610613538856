<template>
    <div class="brand">
        <div class="brand-con">
            <div class="brand-top flex">
                <img src="../../public/images/logo.png" alt="" @click="$router.push('/home')" />
                <div class="top-info flex">
                    <div @click="$router.push('/brand/component')">{{ $t('brand.desc1') }}</div>
                    <div @click="$router.push('/brand/resource')">{{ $t('brand.desc2') }}</div>
                   
                </div>
            </div>
            <div class="brand-tips">{{ $t('brand.desc3') }}</div>
            <div class="brand-map">{{ $t('brand.desc4') }}</div>
            <div class="brand-resource flex">
                <div class="resource-left flex">
                    <div class="flexcenter" @click="$router.push('/brand/resource')">{{ $t('brand.desc5') }}</div>
                    <div>{{ $t('brand.desc6') }} <span>{{ $t('brand.desc7') }}</span> </div>
                </div>
                <div class="resource-right flex">
                    <div>{{ $t('brand.desc8') }}</div>
                    <div>{{ $t('brand.desc9') }}</div>
                </div>
            </div>
            <div class="brand-footer flex">
                <div class="footer-land flex">
                    <div>{{ $t('brand.desc10') }}</div>
                    <div>{{ $t('brand.desc11') }}</div>
                </div>
                <div class="footer-logo">
                    {{ $t('brand.desc12') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    mounted() {
       
    }
}
</script>

<style lang="less" scoped>
div{
    font-family: 'Montserrat';
}
    .brand{
        width: 100%;
        min-height: 100vh;
        background: url('../../public/images/brand/bg.png') no-repeat center center / cover;
        background-size:1110px 1110px;
        background-color: #000;
        vertical-align: middle;
        .brand-con{
            padding: 35px 100px 0;
            .brand-top{
                justify-content: space-between;
                img{
                    flex: 0 0 189px;
                    width: 189px;
                    height: 30px;
                    cursor: pointer;
                }
                .top-info{
                    font-size: 18px;
                    color: #fff;
                    line-height: 30px;
                    cursor: pointer;
                   
                    div{
                       
                        text-transform: capitalize;
                        &:last-child{
                            margin-left: 70px;
                        }
                    }
                }
            }
            .brand-tips{
                margin: 230px 0 67px;
                font-size: 14px;
                color: #fff;
                line-height: 14px;
                text-transform: uppercase;
                
            }
            .brand-map{
                font-size: 76px;
                color: #fff;
                line-height: 76px;
          
                text-transform: uppercase;
            }
            .brand-resource{
                padding: 80px 0 235px;
                justify-content: space-between;
                div,span{
                    
                    text-transform:uppercase;
                }
                .resource-left{
                    flex: 1;
                    div{
                        &:first-child{
                            flex: 0 0 375px;
                            height: 60px;
                            font-size: 16px;
                            color: #fff;
                            border: 1px solid #bbb;
                            box-sizing: border-box;
                            cursor: pointer;
                        }
                        &:last-child{
                            margin-left: 60px;
                            line-height: 60px;
                            font-size: 20px;
                            color: #fff;
                            span{
                                color: #FDB500;
                            }
                        }
                    }
                }
                .resource-right{
                    line-height: 60px;
                    font-size: 14px;
                    color: #fff;
                    div{
                        &:last-child{
                            margin-left: 55px;
                        }
                    }
                }
            }
            .brand-footer{
                justify-content: space-between;
                .footer-land{
                    flex: 1;
                    div{
                        position: relative;
                        font-size: 14px;
                        color: #fff;
                        line-height: 14px;
                        padding-left: 88px;
                        text-transform: uppercase;
                        &::after{
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 60px;
                            height: 1px;
                            background: #D9D9D9;
                            transform: translateY(-50%);
                        }
                       &:last-child{
                        margin-left: 160px;
                       }
                    }
                }
                .footer-logo{
                    font-size: 14px;
                        color: #fff;
                        line-height: 14px;
                        padding-left: 88px;
                        text-transform: uppercase;
                }
            }
        }
    }
    @media (max-width:1200px) {
        .brand{
            padding: 0 57px;
            background: url('../../public/images/brand/smallbg.png') center no-repeat;
            background-size: cover;
            .brand-con{
                padding: 18px 0 0;
                .brand-top{
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    img{
                       flex: 0 0 112px;
                       width: 112px;
                       height: 18px;
                    }
                    .top-info{
                        margin-top: 70px;
                        flex: 0 0 100%;
                        justify-content: space-between;
                        div{
                            font-size: 14px;
                        }
                    }
                }
                .brand-tips{
                    margin: 94px 0 0;
                    font-size: 12px;
                    line-height: 10px;
                }
                .brand-map{
                    font-size: 24px;
                    line-height: 33px;
                }
                .brand-resource{
                    padding: 94px 0 80px;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .resource-left{
                        flex: 0 0 100%;
                        flex-wrap: wrap;
                        div{
                            flex: 0 0 100%;
                            &:first-child{
                                flex: 0 0 100%;
                                height: 39px;
                                font-size: 12px;
                            }
                            &:last-child{
                                margin-left: 0;
                                font-size: 12px;
                                text-align: center;
                            }
                        }
                    }
                    .resource-right{
                       display: none;
                    }
                }
                .brand-footer{
                    justify-content: center;
                    .footer-land{
                        flex: 0 0 100%;
                        justify-content: flex-end;
                        div{
                            padding-left: 47px;
                            font-size: 12px;
                            line-height: 10px;
                            &:last-child{
                                margin-left: 21px;
                            }
                            &::after{
                                width: 42px;

                            }
                        }
                    }
                    .footer-logo{
                        display: none;
                    }
                }
            }
        }
    }
</style>